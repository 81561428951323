import { Box, Button, Card, Checkbox, createStyles, Grid, makeStyles, TextField, Typography, Tooltip } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { FC, Fragment, ReactNode, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import { useReduxSelector } from "../../store/selectors";
import { getWorkspace, getWorkspaceCompanyInfo, getWorkspaceProvisionRate } from "../../store/selectors/workspace.selectors";
import { useRouter } from "next/router";
import {
    getRealEstatePrice,
    getRealEstateId,
    getRealEstateOneClickDeal,
    getRealEstateProvision,
    getRealEstate,
    getCurrency,
    getRealEstateOwner
} from "../../store/selectors/real-estate.selectors";
import { getContactId, getMode } from "../../store/selectors/contact.selectors";
import { $updateRealEstateDeprecated } from "../../store/thunks/real-estate.thunk";
import { $sendBrokerDealDoneEmail } from "../../store/thunks/contact.thunk";
import { changeUrlDomain, CurrencyShort, formatDateWithTime, getSeparators } from "../../utils/formatters";
import DealConfirmedCard from "./deal-confirmed-card";
import { createSelector } from "@reduxjs/toolkit";
import { useAppDispatch } from "../../store";
import { RealEstate, Workspace } from "@onpreo/database/src/definitions";
import { useSelector } from "react-redux";
import { LoadingButton } from "@onpreo/components";
import Confetti from "../confetti";
import { $createNewActivity } from "../../store/thunks/activity.thunk";
import { getRealEstateChangeId } from "../../store/selectors/real-estate-changes-selector";

const useItemStyles = makeStyles(theme =>
    createStyles({
        label: {
            fontWeight: 600,
            fontSize: 14
        },
        textField: {
            width: "100%"
        }
    })
);

const Item: FC<{ label: string; component: ReactNode }> = ({ label, component }) => {
    const classes = useItemStyles();

    return (
        <Grid item xs={12} sm={6}>
            <Grid container direction={"column"} spacing={1}>
                <Grid item>
                    <Typography className={classes.label}>{label}</Typography>
                </Grid>
                <Grid item>{component}</Grid>
            </Grid>
        </Grid>
    );
};

const useProvisionStyles = makeStyles(theme =>
    createStyles({
        label: {
            // fontWeight: 300,
            fontSize: 16,
            padding: theme.spacing(0.5, 0)
        }
    })
);

const getBuyerCourtage = createSelector(
    getWorkspaceProvisionRate,
    getRealEstateProvision,
    // NOTE: This means take the real estate specific rate, fallback to workspace default rate, and then if that was not set (which we
    // should probably enforce though) fallback to 2.49, so that we dont display `undefined%`
    (w, r) => r?.buyerCommission ?? w?.buyerCommission ?? 2.49
);

const getSellerCourtage = createSelector(
    getWorkspaceProvisionRate,
    getRealEstateProvision,

    (w, r) => r?.sellerCommission ?? w?.sellerCommission ?? 2.49
);

export function getProvisionData(realEstate: RealEstate, workspace: Workspace) {
    const hasRealEstateFixedProvision = realEstate?.hasFixedProvision;
    const hasFixedProvision = workspace?.agentSettings?.hasFixedProvision;

    if (hasRealEstateFixedProvision) return true;
    else if (!hasRealEstateFixedProvision && !realEstate?.provisionRate?.sellerCommission && hasFixedProvision) return true;
    // else if (!hasRealEstateFixedProvision && !hasFixedProvision) return false;
    // else if (!hasRealEstateFixedProvision && realEstate?.provisionRate && realEstate?.provisionRate?.sellerCommission) return false;
    else return false;
}

const Provision: FC = () => {
    const classes = useProvisionStyles();
    const buyerRate = useReduxSelector(getBuyerCourtage);
    const sellerRate = useReduxSelector(getSellerCourtage);
    const realEstate = useReduxSelector(getRealEstate);
    const taxWord = realEstate.askingPrice?.currency === "CHF" ? "MwSt." : "USt.";

    return (
        <Box>
            <Grid container justifyContent={"space-between"}>
                <Grid item>
                    <Typography className={classes.label}>Verkäuferprovision im Erfolgsfall:</Typography>
                </Grid>
                <Grid item>
                    <Typography className={classes.label} style={{ marginLeft: -10 }}>
                        {sellerRate} % inkl. {taxWord}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent={"space-between"}>
                <Grid item>
                    <Typography className={classes.label}>Käuferprovision im Erfolgsfall:</Typography>
                </Grid>
                <Grid item>
                    <Typography className={classes.label}>
                        {buyerRate} % inkl. {taxWord}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

const AbsoluteProvision: FC = () => {
    const classes = useProvisionStyles();
    const realEstate = useReduxSelector(getRealEstate);
    const workspace = useReduxSelector(getWorkspace);
    const currencySymbol = useSelector(getCurrency);
    const rate = realEstate?.hasFixedProvision ? realEstate?.fixedProvision : workspace?.agentSettings?.fixedProvision;

    return (
        <Box>
            <Grid container justifyContent={"space-between"}>
                <Grid item>
                    <Typography className={classes.label}>Absolute Provision im Erfolgsfall:</Typography>
                </Grid>
                <Grid item>
                    <Typography className={classes.label}>
                        {rate} {currencySymbol}{" "}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

const useCheckboxStyles = makeStyles(theme =>
    createStyles({
        label: {
            fontWeight: 400,
            fontSize: 14
        },
        checkbox: {
            margin: -9
        }
    })
);
const Checks: FC<{ control: any }> = ({ control }) => {
    const classes = useCheckboxStyles();

    const companyInfo = useReduxSelector(getWorkspaceCompanyInfo);

    return (
        <Box>
            <Grid container justifyContent={"flex-start"} alignItems={"center"} spacing={2}>
                <Grid item>
                    <Controller
                        control={control}
                        name="hasAgreeCancellationPolicy"
                        render={({ field: { value, onChange } }) => (
                            <Checkbox color="primary" checked={value} onChange={onChange} className={classes.checkbox} />
                        )}
                    />
                </Grid>
                <Grid item>
                    <Typography className={classes.label}>
                        Ich habe die{" "}
                        <a target={"_blank"} rel={"noopener noreferrer"} href={companyInfo?.cancellationPolicy?.src ?? "#"}>
                            Widerrufsbelehrung
                        </a>{" "}
                        gelesen und verstanden
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent={"flex-start"} alignItems={"center"} spacing={2}>
                <Grid item>
                    <Controller
                        control={control}
                        name="hasAgreePrivacyPolicy"
                        render={({ field: { value, onChange } }) => (
                            <Checkbox color="primary" checked={value} onChange={onChange} className={classes.checkbox} />
                        )}
                    />
                </Grid>
                <Grid item>
                    <Typography className={classes.label}>
                        Ich akzeptiere die{" "}
                        <a target={"_blank"} rel={"noopener noreferrer"} href={companyInfo?.termsOfUse?.src ?? "#"}>
                            AGB
                        </a>
                        {", "}
                        <a target={"_blank"} rel={"noopener noreferrer"} href={companyInfo?.termsDeal?.src ?? "#"}>
                            Vertragsbedingungen
                        </a>{" "}
                        und{" "}
                        <a target={"_blank"} rel={"noopener noreferrer"} href={changeUrlDomain(companyInfo?.privacyPolicy) ?? "#"}>
                            Datenschutzbestimmungen
                        </a>
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

const useStyles = makeStyles(theme =>
    createStyles({
        card: {
            padding: theme.spacing(3),
            maxWidth: 630,
            margin: "auto"
        },
        header: {
            fontSize: 24,
            fontWeight: 600
        },
        description: {
            fontSize: 16,
            fontWeight: 300
        },
        flexBreak: {
            height: 1,
            flexBasis: "100%"
        },
        quoteBox: {
            backgroundColor: theme.palette.primary.main,
            width: 440,
            padding: theme.spacing(3)
        },
        quote: {
            fontSize: 18,
            fontWeight: 500
        },
        imageContainer: {
            width: 380,
            height: 380,
            position: "relative",
            borderRadius: "50%",
            border: "2px solid white",
            overflow: "hidden",
            marginBottom: theme.spacing(-3)
        },
        image: {
            width: "100%",
            height: "100%",
            objectFit: "cover"
        },
        buttonBlue: {
            backgroundColor: "#0071E3",
            border: "1px solid transparent",

            "&:hover": {
                backgroundColor: "#3898EC"
            }
        }
    })
);

const periodOptions = [
    { value: 3, label: "3 Monat" },
    { value: 6, label: "6 Monate" },
    { value: 9, label: "9 Monate" },
    { value: 12, label: "12 Monate" },
    { value: 18, label: "18 Monate" },
    { value: 24, label: "24 Monate" }
];

type OneClickDealProps = {
    variant?: "teaser" | "actual";
};

export const OneClickDeal: FC<OneClickDealProps> = ({ variant = "actual" }) => {
    const classes = useStyles();
    const router = useRouter();
    const isDisabled = variant === "teaser";
    const price = useReduxSelector(getRealEstatePrice);
    const currentRealEstateId = useReduxSelector(getRealEstateId);
    const currentContactId = useReduxSelector(getContactId);
    const currentDeal = useReduxSelector(getRealEstateOneClickDeal);
    const currentOwner = useReduxSelector(getRealEstateOwner);
    const id = useReduxSelector(getRealEstateChangeId);
    const dispatch = useAppDispatch();
    const [disabledBtn, setDisabledBtn] = useState(true);
    const realEstate = useReduxSelector(getRealEstate);
    const workspace = useReduxSelector(getWorkspace);
    const hasAbsolute = getProvisionData(realEstate, workspace);
    const currencySymbol = useSelector(getCurrency);
    const mode = useReduxSelector(getMode);
    const [showConfetti, setShowConfetti] = useState(false);

    const { thousandSeparator, decimalSeparator } = getSeparators();

    const defaultDealData = {
        contractStart: currentDeal?.contractStart || format(new Date(), "dd/MM/yyyy"),
        salesStart: currentDeal?.salesStart || new Date(),
        contractPeriod: currentDeal?.contractPeriod || 6,
        price: CurrencyShort(price) + " " + currencySymbol || `XXX${thousandSeparator}XXX${decimalSeparator}XX${currencySymbol}`,
        hasAgreeCancellationPolicy: false,
        hasAgreePrivacyPolicy: false
    };

    const { control, reset, handleSubmit, watch } = useForm({
        defaultValues: defaultDealData
    });

    const watchFields = watch();

    useEffect(() => {
        if (watchFields?.hasAgreeCancellationPolicy && watchFields?.hasAgreePrivacyPolicy) {
            setDisabledBtn(false);
        } else {
            setDisabledBtn(true);
        }
    }, [watchFields]);

    useEffect(() => {
        reset(defaultDealData);
        setShowConfetti(false);
    }, [price]);

    const handleClick = async data => {
        const oneClickDeal = { ...data, price: price || "", dealConfirmed: true };
        await dispatch($updateRealEstateDeprecated({ realEstateId: currentRealEstateId, realEstate: { oneClickDeal, status: "EXPOSE_DATA_OBTAIN" } }));
        setShowConfetti(true);

        await dispatch($sendBrokerDealDoneEmail({ contactId: currentContactId, realEstateId: currentRealEstateId }));

        const currentDate = formatDateWithTime(new Date());
        const newActivity = {
            realEstate: id,
            activityType: "note",
            isDone: true,
            type: "realEstate",
            activityFields: {
                title: `Eigentümer hat Maklerauftrag angenommen (${currentDate})`,
                owner: currentOwner,
                activityDate: { donedate: new Date() }
            }
        };
        await dispatch($createNewActivity({ activity: newActivity }));
    };

    return (
        <Fragment>
            {showConfetti && <Confetti />}
            {currentDeal?.dealConfirmed && !isDisabled ? (
                <DealConfirmedCard />
            ) : (
                <Card className={classes.card}>
                    {isDisabled && (
                        <div
                            style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                pointerEvents: "none",
                                background: "linear-gradient(0deg, rgba(255,255,255,1) 30%, rgba(0,0,0,0))",
                                zIndex: 9,
                                top: 0,
                                left: 0
                            }}
                        />
                    )}

                    <Grid container spacing={4} direction={"column"}>
                        <Grid item>
                            <Typography className={classes.header}>Beauftragen Sie Ihren Makler direkt mit onpreo</Typography>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={4}>
                                <Item
                                    label={"Vertragsstart"}
                                    component={
                                        <Controller
                                            control={control}
                                            name={"contractStart"}
                                            render={({ field }) => <TextField {...field} variant={"filled"} style={{ width: "100%" }} disabled />}
                                        />
                                    }
                                />
                                <Item
                                    label={"Laufzeit"}
                                    component={
                                        <Controller
                                            control={control}
                                            name={"contractPeriod"}
                                            render={({ field }) => <TextField {...field} disabled variant={"filled"} style={{ width: "100%" }} />}
                                        />
                                    }
                                />
                                <div className={classes.flexBreak} />
                                <Item
                                    label={"Vermarktungsstart"}
                                    component={
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Controller
                                                control={control}
                                                name="salesStart"
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        disabled
                                                        autoOk
                                                        format="dd/MM/yyyy"
                                                        fullWidth
                                                        animateYearScrolling
                                                        variant={"inline"}
                                                        inputVariant={"filled"}
                                                    />
                                                )}
                                            />
                                        </MuiPickersUtilsProvider>
                                    }
                                />
                                <Item
                                    label={"Vermarktungspreis"}
                                    component={
                                        <Controller
                                            control={control}
                                            name={"price"}
                                            render={({ field }) => <TextField {...field} variant={"filled"} style={{ width: "100%" }} disabled />}
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid item>{hasAbsolute ? <AbsoluteProvision /> : <Provision />}</Grid>
                        <Grid item>
                            <Checks control={control} />
                        </Grid>
                        <Grid item>
                            <Grid container justifyContent={"center"}>
                                <Grid item>
                                    {isDisabled ? (
                                        <Tooltip title={mode === "demo" ? "Im Demo-Modus nicht verfügbar" : ""}>
                                            <Button
                                                onClick={() => router.push("/deal")}
                                                color={"primary"}
                                                variant={"contained"}
                                                className={classes.buttonBlue}
                                                style={{ zIndex: 10 }}
                                            >
                                                Zum Maklerauftag
                                            </Button>
                                        </Tooltip>
                                    ) : (
                                        <LoadingButton
                                            onClick={handleSubmit(handleClick)}
                                            color={"primary"}
                                            variant={"contained"}
                                            className={classes.buttonBlue}
                                            disabled={disabledBtn}
                                        >
                                            <Typography>Provisionspflichtig beauftragen</Typography>
                                            <Typography style={{ fontSize: 14 }}>Provision wird erst nach erfolgreichem Verkauf fällig</Typography>
                                        </LoadingButton>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            )}
        </Fragment>
    );
};
