import { ImageDetails, RealEstateDocument } from "@onpreo/database";
import axios from "axios";
import { DeepPartial } from "react-hook-form";
import { FileDescriptor, imageHandler } from "@onpreo/toolbox/src/client";
import { onpreoClient } from "@onpreo/upsy-daisy/client";

const updateRealEstate = async (realEstateId: string, realEstate: DeepPartial<RealEstateDocument>, variant?: string) => {
    const url = `/api/real-estate/${realEstateId}`;
    return await onpreoClient.put<{ realEstate: RealEstateDocument }>(url, { realEstate, variant });
};

const uploadAsset = async (id: string, file: Blob, fileName?: string) => {
    const url = `/api/real-estate/${id}/asset`;
    const descriptor: FileDescriptor = { originalFilename: fileName ?? (file as any).name, mimetype: file.type };
    const response = await onpreoClient.post(url, descriptor);
    await imageHandler.uploadPreSignedFile(response.data.presigned, file);
    return response;
};

const removeAsset = async (id: string, details: ImageDetails) => {
    const url = `/api/real-estate/${id}/remove-asset`;

    return await onpreoClient.delete(url, {
        data: {
            ...details
        }
    });
};

const createNewRealEstate = async (body: any) => {
    const url = "/api/real-estate/create";
    return await onpreoClient.post(url, body);
};

const getDataForCerberusLandingPage = async (realEstateId: string, workspaceId: string, contactId: string) => {
    const url = `/api/cerberus`;
    return await onpreoClient.get(url, { params: { realEstateId, workspaceId, contactId } });
};

const createOrUpdateRealEstateForCerberus = async (body: any) => {
    const url = "/api/cerberus";
    return await onpreoClient.post(url, body);
};

const getLandingPageProps = async (realEstateId: string, workspaceId: string, contactId: string, auth: string) => {
    const url = `/api/cerberus/landing-page`;
    return await onpreoClient.get(url, { params: { realEstateId, workspaceId, contactId, auth } });
};

const realEstateService = {
    updateRealEstate,
    uploadAsset,
    removeAsset,
    createNewRealEstate,
    getDataForCerberusLandingPage,
    createOrUpdateRealEstateForCerberus,
    getLandingPageProps
};

export default realEstateService;
