import { RealEstateDocument } from "@onpreo/database";
import { createSelector } from "@reduxjs/toolkit";
import ReduxState from "../models";
import { getContact } from "./contact.selectors";
import { parseAddress } from "../../utils/formatters";

export const getRealEstateState = (state: ReduxState) => state.realEstate;

export const getActiveRealEstate = createSelector(getRealEstateState, s => s.active);
export const getContactRealEstates = createSelector(getRealEstateState, s => s.guestRealEstates);

export const getRealEstate = createSelector(getRealEstateState, getContactRealEstates, getContact, ({ active }, realEstates, contact) => {
    return realEstates[active] ?? ({} as RealEstateDocument);
});

export const getRealEstateOwner = createSelector(getRealEstate, re => re.owner?.id?.["_id"]);

export const getRealEstateId = createSelector(getRealEstate, realEstate => realEstate._id);
export const getRealEstatePrice = createSelector(
    getRealEstate,
    realEstate => realEstate.askingPrice?.priceAssistant || realEstate.askingPrice?.evaluation || realEstate.askingPrice?.estimate
);

export const getRealEstateOneClickDeal = createSelector(getRealEstate, realEstate => realEstate?.oneClickDeal);
export const getRealEstateExposeStatus = createSelector(getRealEstate, realEstate => realEstate?.expose?.status);

export const getRealEstateProvision = createSelector(getRealEstate, r => r.provisionRate);

export const getResponsibleUser = createSelector(getRealEstate, re => re.responsibleUser);
export const getResponsibleUserGender = createSelector(getResponsibleUser, responsibleUser => responsibleUser?.gender);
export const getResponsibleUserMeta = createSelector(getResponsibleUser, responsibleUser => responsibleUser?.meta);
export const getResponsibleUserName = createSelector(getResponsibleUserMeta, meta => meta?.name);
export const getResponsibleUserPhone = createSelector(getResponsibleUserMeta, meta => meta?.phone);
export const getResponsibleUserEmail = createSelector(getRealEstate, re => re.responsibleUser?.email);
export const getResponsibleUserImage = createSelector(getResponsibleUserMeta, meta => meta?.img);
export const getCurrency = createSelector(getRealEstate, re => {
    if (re.askingPrice?.currency === "CHF") return " CHF";
    else return " €";
});
export const getPriceAssistantPdf = createSelector(getRealEstate, re => re?.priceAssistantPdf);
export const getRealEstateExposePhotos = createSelector(getRealEstate, re => re?.expose?.propertyPhotos);

export const getRealEstateOptions = createSelector(getContactRealEstates, s => s.map((r, index) => ({ index, label: parseAddress(r.address) })));
