import { createAsyncThunk } from "@reduxjs/toolkit";
import activityService from "../services/activity.service";

interface createNewActivityThunk {
    activity: any;
}

export const $createNewActivity = createAsyncThunk("activity/$createNewActivity", async ({ activity }: createNewActivityThunk, thunkAPI) => {
    const response = await activityService.createNewActivity(activity.realEstate, activity);

    return response.data;
});
