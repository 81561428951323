import { SaveFormThunkArgs } from "@onpreo/components";
import { Contact } from "@onpreo/database";
import { createNotification, enqueueSnackbar } from "@onpreo/slices";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createAppAsyncThunk } from "..";
import { trackUserPilotActivation, UserPilotEvents } from "../../utils/userpilot/userpilot-events";
import contactService from "../services/contact.service";
import { setContact, setUpdatedGuestById } from "../slices/contact.slice";
// import { enqueueSnackbar } from "../slices/snackbar.slice";
// import { createFailureNotification } from "../../utils/snacker";

export const $fetchContacts = createAsyncThunk("contact/$fetchContacts", async (a, thunkAPI) => {
    const response = await contactService.fetchContacts();
    thunkAPI.dispatch(setContact(response.data.contact));
    return response.data;
});

export const $updateContact = createAsyncThunk("contact/$updateContact", async ({ id, value }: SaveFormThunkArgs<Contact>, thunkAPI) => {
    const response = await contactService.updateContact(id as string, value);
    thunkAPI.dispatch(setContact(response.data.contact));
    thunkAPI.dispatch(setUpdatedGuestById({ id: id as string, updatedContact: response.data.contact }));
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Ihre Daten wurden erfolgreich aktualisiert", "success")));
    return response.data;
});

export const $deleteContact = createAsyncThunk("contact/$deleteContact", async (contactId: string, thunkAPI) => {
    const response = await contactService.deleteContact(contactId);
    thunkAPI.dispatch(setContact(response.data.contact));
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Ihre Account wurde erfolgreich gelöscht", "success")));
    return response.data;
});

export const $deleteAllAccounts = createAsyncThunk("contact/$deleteAllAccounts", async (_, thunkAPI) => {
    const response = await contactService.deleteAllAccounts();
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Ihre Account wurde erfolgreich gelöscht", "success")));
    return response.data;
});

export const $notifyAllOwners = createAsyncThunk("contact/notifyAllOwners", async (emailIdList: {}, thunkAPI) => {
    const response = await contactService.notifyAllOwners(emailIdList);
    return response.data;
});

export const $sendNotaryDataReadyEmail = createAppAsyncThunk(
    "contact/sendNotaryDataReadyEmail",
    async (payload: { contactId: string; realEstateId: string }, thunkAPI) => {
        await contactService.sendNotaryDataReadyEmail(payload);
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Der Makler wurde kontaktiert!", "success")));
    }
);

export const $sendNoEnergyCertificateEmail = createAppAsyncThunk(
    "contact/$sendNoEnergyCertificateEmail",
    async (payload: { contactId: string; realEstateId: string }, thunkAPI) => {
        await contactService.sendNoEnergyCertificateEmail(payload);
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Der Makler wurde kontaktiert!", "success")));
    }
);

export const $sendExposeDataReadyEmail = createAppAsyncThunk(
    "contact/sendExposeDataReadyEmail",
    async (payload: { contactId: string; realEstateId: string }, thunkAPI) => {
        await contactService.sendExposeDataReadyEmail(payload);
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Der Makler wurde kontaktiert!", "success")));
    }
);

export const $sendNotOwnerEmail = createAppAsyncThunk(
    "contact/$sendNotOwnerEmail",
    async (payload: { contactId: string; realEstateId: string }, thunkAPI) => {
        await contactService.sendNotOwnerEmail(payload);
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Der Makler wurde kontaktiert!", "success")));
    }
);
export const $sendAppointmentSuggestionEmail = createAppAsyncThunk("contact/sendAppointmentSuggestionEmail", async (contactId: string, thunkAPI) => {
    await contactService.sendAppointmentSuggestionEmail(contactId);
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Der Makler wurde kontaktiert!", "success")));
});

export const $sendBrokerDealDoneEmail = createAppAsyncThunk(
    "contact/sendBrokerDealDoneEmail",
    async (payload: { contactId: string; realEstateId: string; variant?: string }, thunkAPI) => {
        await contactService.sendBrokerDealDoneEmail(payload);
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Der Makler wurde kontaktiert!", "success")));

        if (payload?.variant === "ONE_CLICK_DEAL") await trackUserPilotActivation(UserPilotEvents.ONE_CLICK_DEAL.FIRST_ORDER);
        else await trackUserPilotActivation(UserPilotEvents.GUEST_DASHBOARD.FIRST_ORDER);
    }
);

export const $getPriceAssistantPdf = createAsyncThunk(
    "contacts/$getPriceAssistantPdf",
    async (payload: { contactId: string; realEstateId: string }, thunkAPI) => {
        const response = await contactService.getPriceAssistantPdf(payload.contactId, payload.realEstateId);
        thunkAPI.dispatch(enqueueSnackbar(createNotification("PDF-Datei erfolgreich geöffnet!", "success")));
        return response.data;
    }
);

export const $resetContactPassword = createAsyncThunk("user/$resetContactPassword", async (passwordDetails: any, thunkAPI) => {
    const response = await contactService.resetContactPassword(passwordDetails);
    return response;
});

export const $checkIfContactExistInWorkspace = createAsyncThunk(
    "contacts/$checkIfContactExistInWorkspace",
    async ({ workspaceId, email }: { workspaceId: string; email: string }, thunkAPI) => {
        try {
            const response = await contactService.checkIfContactExistInWorkspace(workspaceId, email);
            return response.data;
        } catch (error) {
            console.log(error);
            thunkAPI.dispatch(enqueueSnackbar(createNotification("Workspace not found!", "warning")));
        }
    }
);

export const $signupContactWithWorkspaceId = createAsyncThunk(
    "contacts/$signupContactWithWorkspaceId",
    async ({ workspaceId, passwordDetails }: { workspaceId: string; passwordDetails: any }, thunkAPI) => {
        const response = await contactService.signupContactWithWorkspaceId({ workspaceId, passwordDetails });
        return response.data;
    }
);
