import { replace } from "lodash";
import numeral from "numeral";
require("numeral/locales/de");
import { Address } from "@onpreo/database/src";
import { format } from "date-fns";
import { de } from "date-fns/locale";

numeral.locale("de");
numeral.localeData("de").delimiters.thousands = ".";

// ----------------------------------------------------------------------

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    key: "Hello"
                }
            },
            de: {
                translation: {
                    key: "Hallo"
                }
            }
        },
        fallbackLng: "de",
        detection: {
            order: ["navigator", "cookie", "htmlTag"],
            caches: ["cookie", "localStorage"]
        },
        interpolation: {
            escapeValue: false
        }
    });

export function getSeparators() {
    const locale = i18n.language;
    const formatter = new Intl.NumberFormat(locale, { useGrouping: true });
    const parts = formatter.formatToParts(123456);
    let thousandSeparator = "";
    let decimalSeparator = "";

    for (let part of parts) {
        if (part.type === "group") {
            thousandSeparator = part.value;
        } else if (part.type === "decimal") {
            decimalSeparator = part.value;
        }
    }

    // special case for Swiss local to use ' instead of ´
    if (locale === "de-CH") {
        thousandSeparator = "'";
    }

    // make sure to set a default thousand separator if none is found
    thousandSeparator = thousandSeparator || ".";
    decimalSeparator = decimalSeparator || ",";

    return { thousandSeparator, decimalSeparator };
}

export function currencyCodeToSymbol(currencyCode, locale = "en") {
    const formatter = new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currencyCode,
        minimumFractionDigits: 0
    });

    // formatting a symbolic number to extract the currency format
    const formatted = formatter.format(0);

    // delete numeric and extra characters to get only the currency symbol
    const symbol = formatted.replace(/[\d.,\s]/g, "").trim();

    return " " + symbol;
}

// include checker for currency Format
export function CurrencyShort(number) {
    const locale = i18n.language;
    const formatter = new Intl.NumberFormat(locale, {
        style: "decimal",
        useGrouping: true,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });

    let formattedNumber = formatter.format(number);

    if (locale === "de-CH") {
        const parts = formatter.formatToParts(123456);
        let incorrectSeparator = "";

        for (let part of parts) {
            if (part.type === "group") {
                incorrectSeparator = part.value;
                break;
            }
        }

        const correctSeparator = "'";
        formattedNumber = formattedNumber.replace(new RegExp(incorrectSeparator, "g"), correctSeparator);
    }

    return formattedNumber;
}

export function Currency(number) {
    return numeral(number).format(Number.isInteger(number) ? "0,0" : "0,0.00") + " €";
}

export function Percent(number) {
    return numeral(number / 100).format("0.0%");
}

export function formatNumber(number) {
    return numeral(number).format();
}

export function ShortenNumber(number) {
    return replace(numeral(number).format("0.00a"), ".00", "");
}

export function Data(number) {
    return numeral(number).format("0.0 b");
}

export function showFormatDate(date, isUnixTimestamp = false) {
    return format(new Date(date * (isUnixTimestamp ? 1000 : 1)), "dd.MM.yyyy");
}
export function saveFormatDate(value, separator) {
    const [day, month, year] = value.split(separator);
    const formatedDate = day && month && year ? new Date(+year, month - 1, +day) : value;

    return formatedDate;
}

export function parseAddress(address: Address) {
    return `${address?.street} ${address?.house_number}, ${address?.zip} ${address?.town}, ${address?.nation}`;
}

export function parseCategory(cat: "ETW" | "EFH" | "MFH" | "GRD") {
    switch (cat) {
        case "ETW":
            return "Eigentumswohnung";
        case "EFH":
            return "Einfamilienhaus";
        case "MFH":
            return "Mehrfamilienhaus";
        case "GRD":
            return "Grundstck";
        default:
            return "Unbekannt";
    }
}

export function changeUrlDomain(url: string) {
    if (url && url.startsWith("http")) return url;
    else return `https://${url}`;
}

export function formatDateWithTime(date) {
    return format(new Date(date), "dd.MM.yyyy, HH:mm", { locale: de });
}

export default {
    Currency,
    Percent,
    formatNumber,
    ShortenNumber,
    Data,
    showFormatDate,
    saveFormatDate,
    changeUrlDomain
};
