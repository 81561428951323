import { SaveFormThunkArgs } from "@onpreo/components";
import { ImageDetails, RealEstate, Valuation } from "@onpreo/database";
import { createNotification, enqueueSnackbar } from "@onpreo/slices";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createAppAsyncThunk } from "..";
import realEstateService from "../services/real-estate.service";
import { updateRealEstate } from "../slices/real-estate.slice";
import { State } from "@onpreo/forms/src/create-forms/generator-state";

interface updateRealEstateThunk {
    realEstateId: string;
    realEstate: any;
    variant?: string;
}

// DEPRECATED: in favour of the save form integration,
// will be left here, so i dont break anything
export const $updateRealEstateDeprecated = createAsyncThunk(
    "real-estate/$updateRealEstate",
    async ({ realEstateId, realEstate, variant }: updateRealEstateThunk, thunkAPI) => {
        const response = await realEstateService.updateRealEstate(realEstateId, realEstate, variant);
        thunkAPI.dispatch(updateRealEstate({ id: realEstateId, realEstate: response.data.realEstate }));
        return response.data;
    }
);

export const $updateRealEstate = createAppAsyncThunk("real-estate/$updateRealEstate", async ({ id, value }: SaveFormThunkArgs<RealEstate>, thunkAPI) => {
    const response = await realEstateService.updateRealEstate(id as string, value);
    thunkAPI.dispatch(updateRealEstate({ id: id as string, realEstate: response.data.realEstate }));
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Ihre Immobilie wurden erfolgreich aktualisiert", "success")));
});

export const $uploadAsset = createAppAsyncThunk(
    "real-estate/$uploadAsset",
    async ({ id, file, fileName }: { id: string; file: Blob; fileName?: string }, thunkAPI) => {
        const response = await realEstateService.uploadAsset(id, file, fileName);
        return response.data;
    }
);

export const $removeAsset = createAppAsyncThunk("real-estate/$remoteAsset", async ({ id, details }: { id: string; details: ImageDetails }, thunkAPI) => {
    try {
        await realEstateService.removeAsset(id, details);
        // if we didnt fuck up here, indicate the success
        return true;
    } catch (_) {
        return false;
    }
});

export const $createNewRealEstate = createAsyncThunk("real-estate/$createNewRealEstate", async (body, thunkAPI) => {
    const response = await realEstateService.createNewRealEstate(body);
    return response.data;
});

export const $getDataForCerberusLandingPage = createAsyncThunk(
    "real-estate/$getDataForCerberusLandingPage",
    async ({ realEstateId, workspaceId, contactId }: { realEstateId: string; workspaceId: string; contactId: string }, thunkAPI) => {
        const response = await realEstateService.getDataForCerberusLandingPage(realEstateId, workspaceId, contactId);
        return response.data;
    }
);

interface CreateRealEstate {
    state: State;
    valuation: Valuation;
    lead: any;
    evaluationType: "sprengnetter" | "priceHubble";
    realEstateId?: string;
    workspaceId?: string;
    contactId?: string;
}

export const $createOrUpdateRealEstateForCerberus = createAsyncThunk(
    "real-estate/$createOrUpdateRealEstateForCerberus",
    async (body: CreateRealEstate, thunkAPI) => {
        const response = await realEstateService.createOrUpdateRealEstateForCerberus(body);
        return response.data;
    }
);

export const $getLandingPageProps = createAsyncThunk(
    "real-estate/$getLandingPageProps",
    async ({ realEstateId, workspaceId, contactId, auth }: { realEstateId: string; workspaceId: string; contactId: string; auth: string }, thunkAPI) => {
        const response = await realEstateService.getLandingPageProps(realEstateId, workspaceId, contactId, auth);
        return response.data;
    }
);
