import { Grid, createStyles, makeStyles, Typography, Avatar, Box } from "@material-ui/core";
import { useReduxSelector } from "../../../store/selectors";
import { getResponsibleUser } from "../../../store/selectors/real-estate.selectors";
import { getAgentImage, getAgentName, getDefaultImage } from "../../../store/selectors/agent.selector";
import { getSignatureByUser } from "@onpreo/toolbox/src/client";

const useStyles = makeStyles(theme =>
    createStyles({
        avatarWrapper: {
            position: "absolute",
            top: -40,
            left: "calc((100% - 120px)/ 2)",
            zIndex: 10
        },
        avatar: {
            margin: "auto",
            background: theme.palette.grey[300],
            border: "2px solid #FFFFFF",
            width: 120,
            height: 120
        },
        textWrapper: {
            backgroundColor: "#fff",
            borderRadius: ".375rem",
            color: theme.palette.primary.main
        },
        cardWrapper: {
            position: "relative",
            padding: "56px 24px 0"
        }
    })
);

const DealConfirmedCard = () => {
    const classes = useStyles();
    const agentName = useReduxSelector(getAgentName);
    const agentImage = useReduxSelector(getAgentImage);
    const defaultImage = useReduxSelector(getDefaultImage);

    const responsibleUser = useReduxSelector(getResponsibleUser);
    const { image: userImage, name } = getSignatureByUser(responsibleUser);
    const image = userImage?.src || agentImage?.src || defaultImage?.src;

    return (
        <Grid container justifyContent="center" className={classes.cardWrapper}>
            <Box className={classes.avatarWrapper}>
                <Avatar src={image || "/makler.jpeg"} alt="Makler picture" className={classes.avatar} />
            </Box>

            <Box className={classes.textWrapper} mt={-1} padding={4}>
                <Typography variant="h2" align="center">
                    {"Vielen Dank für den Auftrag!"}
                </Typography>
                <Typography variant="h4" align="center">
                    {"Wir werden uns zeitnah bei Ihnen melden"}
                </Typography>
                <Typography variant="h4" align="center">
                    {"Ihr Makler"}
                </Typography>
                <Typography variant="h4" align="center">
                    {name || agentName}
                </Typography>
            </Box>
        </Grid>
    );
};

export default DealConfirmedCard;
