import { onpreoClient } from "@onpreo/upsy-daisy/client";

const createNewActivity = async (realEstateId: string, activity: any) => {
    const url = `/api/activity/${realEstateId}`;
    return await onpreoClient.post(url, activity);
};

const activityService = {
    createNewActivity
};

export default activityService;
