import { createSelector } from "@reduxjs/toolkit";
import { getContact } from "./contact.selectors";

export const getWorkspace = createSelector(getContact, c => c?.workspace);

export const getWorkspaceBilling = createSelector(getWorkspace, w => w?.billing);
export const getWorkspaceAgentImage = createSelector(getWorkspace, w => w?.agentImage);
export const getWorkspaceSettings = createSelector(getWorkspace, w => w?.agentSettings);
export const getLandingPageInfo = createSelector(getWorkspace, w => w?.landingPageInfo);

export const getWorkspaceProvisionRate = createSelector(getWorkspaceSettings, s => s?.provisionRate);
export const getWorkspaceQuote = createSelector(getWorkspaceSettings, s => s.quote);
export const getWorkspaceExtras = createSelector(getWorkspace, workspace => workspace?.extras);

export const getWorkspaceCompanyInfo = createSelector(getWorkspace, workspace => workspace?.companyInfo);
export const getPowerOfAttorney = createSelector(getWorkspaceCompanyInfo, info => info?.powerOfAttorney);
export const getBackground = createSelector(getWorkspaceExtras, extras => extras?.backgroundGuest);

export const getWorkspaceBillingPrice = createSelector(getWorkspaceBilling, billing => billing?.price);
