import { createSelector } from "@reduxjs/toolkit";
import { RealEstateChangesDocument } from "@onpreo/database/src/definitions";
import ReduxState from "../models";
import { getContact } from "./contact.selectors";

export const getGuestRealEstatesChangeState = (state: ReduxState) => state.realEstatesChanges;
export const getActiveGuestRealEstateChange = createSelector(getGuestRealEstatesChangeState, s => s.active);

export const getContactRealEstatesChanges = createSelector(getGuestRealEstatesChangeState, s => s.guestRealEstatesChanges);

export const getRealEstateChange = createSelector(
    getGuestRealEstatesChangeState,
    getContactRealEstatesChanges,
    getContact,
    ({ active }, realEstateChanges, contact) => {
        return realEstateChanges[active] ?? ({} as RealEstateChangesDocument);
    }
);

export const getRealEstateChangeExposeStatus = createSelector(getRealEstateChange, realEstateChange => realEstateChange?.expose?.status);

export const getRealEstateChangeId = createSelector(getRealEstateChange, realEstateChange => realEstateChange?._id);

export const inReview = createSelector(getRealEstateChange, realEstateChanges => realEstateChanges?.isAccepted === "in-review");
